import { CommentStatus } from '../types/Comment';
import {
  CollectionListener,
  createSingleCollectionListenerStore,
  useCollectionListener
} from './collectionListener';
import { commentsCollection, toCommentDoc } from './comments';

export const pendingCommentsStore = createSingleCollectionListenerStore(
  () =>
    new CollectionListener(
      commentsCollection().where('status', '==', CommentStatus.PENDING),
      toCommentDoc
    )
);

export const usePendingComments = () =>
  useCollectionListener(pendingCommentsStore());
