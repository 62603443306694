import { IconButton, Typography } from '@material-ui/core';
import { sortBy } from 'lodash';
import React from 'react';
import { CheckCircle, ExternalLink, Trash2 } from 'react-feather';
import ReactMarkdown from 'react-markdown';
import { Menu } from '../../components/AdminMenu';
import { CommentCreator } from '../../components/Comments/CommentFeed';
import { DeletionConfirmationDialog } from '../../components/DeletionConfirmationDialog';
import { LinkExternal } from '../../components/LinkExternal';
import { ProductHuntBanner } from '../../components/ProductHuntBanner';
import { onlyForAdmins } from '../../components/ProtectedAdminPage';
import { SEO } from '../../components/SEO';
import { FS } from '../../constants';
import { useDialogState } from '../../hooks/useDialogState';
import Layout, { Wrapper } from '../../layouts/Layout';
import { usePendingComments } from '../../services/admin-comments';
import {
  docRefToKey,
  keyToDocRef,
  publishComment,
  removeCommentOrReply,
  toCommentIdHash
} from '../../services/comments';
import { useCurrentUser } from '../../services/currentUser';
import { store, useMappedLoadingValue } from '../../services/db';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../services/documentListener';
import styled from '../../styled';
import { IComment, ICommentContainer } from '../../types/Comment';
import { Doc, DocReference } from '../../types/Document';

const OuterContainer = styled('div')((p) => ({
  marginBottom: p.theme.spacing(4)
}));

const ReferenceHeadline = styled('div')((p) => ({
  marginBottom: p.theme.spacing(1)
}));

const Header = styled('div')((p) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const HeaderSection = styled('div')((p) => ({
  display: 'flex',
  justifyContent: '',
  alignItems: 'center',
  '> :not(:first-child)': {
    marginLeft: p.theme.spacing(1)
  }
}));

const InnerBody = styled('div')((p) => ({
  marginLeft: p.theme.spacing(2)
}));

const Body = styled('div')((p) => ({
  marginLeft: p.theme.spacing(3),
  borderLeft: `1px solid ${p.theme.palette.grey[400]}`,
  paddingTop: p.theme.spacing(2),
  paddingLeft: p.theme.spacing(2),
  paddingBottom: p.theme.spacing(3),

  '> :not(:first-child)': {
    marginTop: p.theme.spacing(0.5)
  }
}));

const ICON_SIZE = 18;

const containerStore = createDocumentListenerGetter<any>((k) => {
  const r = keyToDocRef(k);
  return store().collection(r.collection).doc(r.id);
});
const useContainer = (r: DocReference) =>
  useDocumentListener(containerStore(docRefToKey(r)));

const containerToHrefAndTitle = (doc: Doc<any> | null, commentId: string) => {
  if (!doc) {
    return { href: '', name: 'Removed container' };
  }
  if (doc.collection === FS.commentContainers) {
    const d: ICommentContainer = doc.data;
    return {
      href: `${d.__comments.href}#${toCommentIdHash(commentId)}`,
      title: d.__comments.title
    };
  }
  return { href: '', name: 'Unknown document' };
};

const PendingComment = ({ d }: { d: Doc<IComment> }) => {
  const { user } = useCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const [c, loading] = useMappedLoadingValue(
    useContainer(d.data.entityRef),
    (x) => containerToHrefAndTitle(x, d.id)
  );
  return (
    <OuterContainer>
      <ReferenceHeadline>
        <Typography component="div" variant="overline">
          {loading && <div>Loading entity reference...</div>}
          {c && c.href && <LinkExternal href={c.href}>{c.title}</LinkExternal>}
          {c && !c.href && <div>{c.title}</div>}
        </Typography>
      </ReferenceHeadline>
      <Header>
        <CommentCreator d={d} />
        <HeaderSection>
          <IconButton
            onClick={() =>
              user &&
              publishComment(
                {
                  type: 'USER',
                  id: user.id
                },
                d.id
              )
            }
          >
            <CheckCircle size={ICON_SIZE} />
          </IconButton>
          <IconButton onClick={openDialog}>
            <Trash2 size={ICON_SIZE} />
          </IconButton>
          <IconButton
            disabled={!!c && !c.href}
            href={c && c.href ? c.href : ''}
            target="_blank"
          >
            <ExternalLink size={ICON_SIZE} />
          </IconButton>
        </HeaderSection>
      </Header>
      <Body>
        <InnerBody>
          <ReactMarkdown source={d.data.body} />
        </InnerBody>
      </Body>
      <DeletionConfirmationDialog
        open={dialogOpen}
        onClose={closeDialog}
        onConfirm={async () =>
          user &&
          removeCommentOrReply(
            {
              type: 'USER',
              id: user.id
            },
            d
          )
        }
        unsafe
      />
    </OuterContainer>
  );
};

const IndexPage = () => {
  const [ds] = useMappedLoadingValue(usePendingComments(), (xs) =>
    sortBy(xs, (x) => x.data.createdAt.toMillis())
  );

  // render pending comments
  // show parent if available
  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Admin"
        siteUrl="https://bloggingfordevs.com"
        description="Admin section, lookout!"
        pathname="admin/comments/"
        noIndex
      />
      <Wrapper style={{ maxWidth: '1000px' }}>
        <Menu />
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Comments
          </Typography>
        </div>

        {!!ds && (
          <>
            {ds.map((d) => (
              <PendingComment key={d.id} d={d} />
            ))}
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

export default onlyForAdmins(IndexPage);
